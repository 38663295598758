/* Header */
.header {
  color: #ffffff;
  padding: 60px 0 40px;
  h1 {
    color: rgb(255, 255, 255);
    font-size: 60px;
    font-family: Nunito, sans-serif;
    font-weight: normal;
    text-align: center;
    letter-spacing: 1.67px;
    line-height: 72px;
    font-style: normal;
  }
  h2 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-family: Nunito, sans-serif;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.67px;
    font-style: normal;
  }
}

@media (max-width: 1000px) and (min-width: 480px) {
  .header h1 {
    font-size: calc(30px + 40 * ((100vw - 320px) / 680));
  }
  .header h2 {
    font-size: 24px;
  }
  .header {
    padding: 125px 0 100px;
  }
}
