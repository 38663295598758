$backgroundFiller: #242424;


.mobileMenu {
  .menu {
    background-color: #242424 !important;
    background-image: none !important;
    padding-top: 80px !important;
    a {
      font-family: Nunito, sans-serif !important;
      color: #fff !important;
    }
    .menuDropdown {
      .menuDropdownTitle {
        font-family: Nunito, sans-serif !important;
        color: #fff !important;
        padding: 0 !important;
      }
      font-family: Nunito, sans-serif !important;
      color: #fff !important;
    }
    .foot {
      a {
        color: #f75369 !important;
      }
    }

    .loggedIn {
      background-color: #f75369 !important;
      color: #242424 !important;
      border-radius: 12px !important;
      a {
        color: #242424 !important;
      }
    }

    .loginButton {
      background: #f75369 !important;
      border-radius: 12px !important;
      color: #242424 !important;
      text-transform: uppercase !important;
      font-size: 15px !important;
      font-family: OpenSans, sans-serif !important;
      letter-spacing: 0.42px !important;
      line-height: 22px !important;
    }
  }
}

.logged {
      color: #fff !important;
    }

.container {
  background-color: $backgroundFiller !important;
  min-height: 0 !important;
  height: 60px !important;
  box-shadow: none !important
}

.arrowContainer {
  i {
    margin: 0 !important;
  }
}
.logo {
  margin-bottom: -8px;
}
.dropdown {
  padding-right: 8px !important;
}
.rightMenu {
  align-items: center;
}
.menuElement {
  //width: 36px !important;
  // height: 19px !important;
  color: rgb(255, 255, 255) !important;
  font-size: 14px !important;
  font-family: 'Nunito', sans-serif !important;
  font-weight: 600 !important;
  letter-spacing: 0.39px !important;
  div {
    font-family: 'Nunito', sans-serif !important;
  }
}
.menuButton {
  padding: 7px 20px !important;
  color: rgb(255, 255, 255) !important;
  font-size: 14px !important;
  font-family: 'Nunito', sans-serif !important;
  font-weight: 600 !important;
  letter-spacing: 0.39px !important;
  // width: 94px;
  // height: 32px;
  border: 1px solid rgb(151, 151, 151) !important;
  border-radius: 12px !important;
  margin: 8px !important;
  height: 30px !important;
}
