.faq {
  padding: 0 0 80px;
  background-color: #242424;
  position: relative;
  h2 {
    color: #fff;
  }
  a {
    color: #f75369;
  }
}
.svg-curve {
  height: 60px;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  z-index: 3;
}
.svg-curve svg {
  position: absolute;
  bottom: 0;
  left: 0;
}
.section-title {
  font-size: 50px;
  margin-bottom: 50px;
  text-align: center;
  color: #f4f4f4;
}
.faq a {
  font-size: 20px;
  display: block;
  margin-bottom: 30px;
}
.disabled-link {
  pointer-events: none;
  opacity: 0.5;
}
