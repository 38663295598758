.socialFooter {
  @media (max-width: 768px) {
    text-align: center !important;
  }
}
.container{
  background: #fff !important;
  padding-top: 60px !important;
  .title {
    color: rgb(36, 36, 36);
    font-family: Nunito, sans-serif;
    font-size: 24px;
  }
  .linkList {
    padding-left: 10px;
    @media (max-width: 768px) {
      padding-left: 0;
    }
    a {
      color: rgb(36, 36, 36);
      font-size: 15px;
      letter-spacing: 0.42px;
      &:hover {
        color: #f75369 !important;
      }
    }
  }
  .contactContainer {
    font-family: Nunito, sans-serif;
    .phone, .email {
      color: rgb(36, 36, 36) !important;
      font-family: Nunito, sans-serif !important;
      font-size: 24px !important;
      letter-spacing: 0.67px !important;
      &:hover {
        color: #f75369 !important;
      }
    }
  }
}

.copy {
  color: rgb(36, 36, 36) !important;
  font-size: 12px !important;
  letter-spacing: 0.33px !important;
  font-weight: 400 !important;
  a {
    color: #f75369 !important;
    font-weight: 400 !important;
    text-decoration: underline !important;
  }
}

.bg {
  background-color: #ffffff !important;
}
.shadow {
  display: block;
  background: linear-gradient(-180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
  border-radius: 0;
  height: 10px;
  width: 100%;
}
