.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  flex: 1;
  justify-content: center;
  min-height: 500px;
}

.mobileSubscriptionsContainer {
  background: #242424;
}

/* Plans Cards */
.mobile-plans .ui.fluid.card {
  margin: auto;
  max-width: 265px;
}
.plan-card {
  max-width: 200px;
}
.plan-card .ui.grid {
  margin-left: -3px;
  margin-right: -3px;
}
.plan-card .ui.grid > .row > .column {
  padding-left: 3px;
  padding-right: 3px;
}
.mobile-plans {
  padding: 20px 0 60px;
}
.mobile-plans .ui.grid > .row > .column {
  margin-bottom: 2em;
}
.plan-card .plan-card-title {
  font-size: 30px;
  font-style: normal;
}
.plan-card-info {
  color: #8e8e8e;
  padding: 15px;
  font-size: 14px;
  border-radius: 6px;
  background-color: rgba(242, 220, 233, 0.25);
}
.plan-card-info strong {
  color: #4b4a4d;
  font-size: 22px;
}
.plan-card .plan-card-price {
  color: #4b4a4d;
  font-size: 40px;
  font-weight: 900;
  line-height: 1;
  margin: 0 !important;
}
.plan-card .plan-card-price-detail {
  color: #8e8e8e;
  font-size: 16px;
  margin-bottom: 20px !important;
}
.plan-card .ui.button {
  color: #ffffff;
  font-weight: 600;
  border-radius: 30px;
  margin-bottom: 15px;
  background: linear-gradient(225deg, #fb637e 0%, #ff6900 100%);
}
.plan-card .link-more-info {
  color: #615e9b;
  font-size: 14px;
}
