/* Header */
.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  flex: 1;
  justify-content: center;
  min-height: 500px;
}

.chat {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.faqContainer {
  margin-top: -60px;
}
.header {
  color: #ffffff;
  padding: 60px 0 20px;
  margin-top: 30px;
  background: #242424;
}
.header h1 {
  font-size: 35px;
  margin-bottom: 5px;
}
.disclaimer {
  font-size: 16px;
  opacity: 0.7;
}
@media screen and (min-width: 480px) {
  .header h1 {
    font-size: calc(30px + 40 * ((100vw - 320px) / 680));
  }
  .header h2 {
    font-size: 24px;
  }
  .header {
    padding: 60px 0 10px;
  }
}
@media screen and (min-width: 1000px) {
  .header h1 {
    font-size: 70px;
  }
  .header h2 {
    font-size: 30px;
    margin-top: 40px;
  }
}

.description {
  display: flex;
  justify-content: center;
  margin: 80px auto 40px;
  max-width: 600px;
  color: rgb(255, 255, 255);
  font-family: Nunito, sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  letter-spacing: 1.67px !important;
  font-size: 28px !important;
  @media screen and (max-width: 480px) {
    font-size: 8vw !important;
  }
  text-align: center;
}

.descriptionContainer {
  margin: 20px 0 0;
}

.overview {
    background: #242424;
}
.information {
  padding: 100px 0;
  background: #242424;
}
/* Product Cards */

.productSection .ui.fluid.card {
  margin: auto;
  padding-bottom: 20px;
}
.product-card-disabled {
  opacity: 0.5;
}
.product-card .ui.grid {
  margin-left: -3px;
  margin-right: -3px;
}
.product-card .ui.grid > .row > .column {
  padding-left: 3px;
  padding-right: 3px;
}
.productSection {
  padding: 0;
  background-color: #242424;
}
.productSection .ui.grid > .row > .column {
  margin-bottom: 2em;
}
.product-card .product-card-title {
  font-size: 30px;
  font-style: normal;
  color: #4b4a4d;
}
.product-card-info {
  color: rgb(36, 36, 36);
  padding: 10px;
  font-size: 15px;
  border-radius: 6px;
  background-color: rgb(253, 221, 225);
  min-height: 85px;
}
.product-card-info strong {
  color: #4b4a4d;
  font-size: 22px;
}
.product-card .product-card-price {
  color: #4b4a4d;
  font-size: 40px;
  font-weight: 900;
  line-height: 1;
  margin: 0 !important;
}
.product-card .product-card-price-detail {
  color: #8e8e8e;
  font-size: 16px;
  margin-bottom: 20px !important;
}
.cardButton {
  color: #ffffff;
  font-weight: 600;
  border-radius: 30px;
  margin-bottom: 15px;
  background: linear-gradient(225deg, #ff1b44 0%, #f75369 100%) !important;
}
.product-card .link-more-info {
  color: #999;
  font-size: 14px;
}
.greendot {
  height: 10px;
  width: 10px;
  background-color: #00ff00;
  border-radius: 50%;
  display: inline-block;
}
.yellowdot {
  height: 10px;
  width: 10px;
  background-color: yellow;
  border-radius: 50%;
  display: inline-block;
}
.link-to-product {
  color: #615e9b;
  font-size: 14px;
}
.infoBackground {
    background: linear-gradient(227.79deg, #ff1b44 0%, #f75369 100%) !important;
}
