.cookiesContainer {
  position: fixed;
  -webkit-backface-visibility: hidden;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .cookiesBox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    color: #000;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    height: 40px;
    width: 100%;
    box-shadow: 0 2px 0 0 #333, 0 0 2px 0 #333;
    padding: 5px;
    @media screen and (max-width: 760px) {
      font-size: 10px;
    }
    .text {
      a {
        color: #000;
        opacity: 1;
        transition: opacity .25s ease-in-out;
          -moz-transition: opacity .25s ease-in-out;
          -webkit-transition: opacity .25s ease-in-out;
        text-decoration: underline;
        &:hover {
          opacity: 0.5;
          cursor: pointer;
        }
      }
    }
    .accept {
      display: flex;
      background-color: #F75369;
      border: 0;
      outline: none;
      padding: 4px 10px;
      color: #fff;
      border-radius: 12px;
      font-size: 14px;
      font-family: Nunito, sans-serif;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.39px;
      transition: opacity .25s ease-in-out;
        -moz-transition: opacity .25s ease-in-out;
        -webkit-transition: opacity .25s ease-in-out;
      &:hover {
        opacity: 0.5;
        cursor: pointer;
      }
    }
  }
}
