.card {
  background: rgb(56, 56, 56);
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  height: 480px;
  padding: 20px 0;
  .logo {
    display: flex;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    img {
      display: flex;
      height: 20px;
    }
  }
  .header {
    display: flex;
    height: 90px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      color: rgb(255, 255, 255);
      font-size: 52px;
      font-family: Nunito, sans-serif;
      font-weight: normal;
      text-align: center;
      letter-spacing: 1.44px;
      line-height: 45px;
      font-style: normal;
      margin-bottom: 5px;
    }
    h3 {
      color: rgb(165, 221, 238);
      font-size: 15px;
      font-family: Nunito, sans-serif;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.42px;
      line-height: 30px;
      font-style: normal;
      margin-top: 0;
      height: 25px;
      margin-bottom: 10px;
    }
  }
  .boxCorners {
    position: relative;
    display: flex;
    flex: 1;
    .right {
      position: absolute;
      top: -3px;
      right: -3px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 3px 0 0 3px;
      border-color: transparent transparent transparent rgb(24, 115, 150);
    }
    .left {
      position: absolute;
      top: -3px;
      left: -3px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 3px 3px;
      border-color: transparent transparent rgb(24, 115, 150) transparent;
    }
  }
  .boxes {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(32, 154, 200);
    margin-left: -3px;
    margin-right: -3px;
    .leftBox, .rightBox {
      width: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 15px 0;
      padding: 0 30px;
      p {
        margin: 0;
      }
      .value {
        color: rgb(255, 255, 255);
        font-size: 24px;
        font-family: OpenSans, sans-serif;
        font-weight: 600;
        text-align: center;
        letter-spacing: 0.67px;
        line-height: 30px;
      }
      .label {
        color: rgb(255, 255, 255);
        font-size: 12px;
        font-family: OpenSans, sans-serif;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.33px;
        line-height: 30px;
      }
    }
    .leftBox {
      border-right: 1px solid #fff;
    }
  }
  .priceBox {
    display: flex;
    height: 110px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
      margin: 0
    }
    .price {
      color: rgb(255, 255, 255);
      font-size: 60px;
      font-family: Nunito, sans-serif;
      font-weight: 800;
      text-align: center;
      letter-spacing: 1.67px;
    }
    .priceText {
      color: rgb(255, 255, 255);
      font-size: 15px;
      font-family: OpenSans, sans-serif;
      font-weight: normal;
      text-align: center;
      letter-spacing: 0.42px;
    }
  }
  .buttonContainer {
    display: flex;
    margin: 20px 0;
    justify-content: center;
    align-items: center;
    button {
      background: rgb(32, 154, 200);
      border-radius: 12px;
      border: 0;
      color: rgb(255, 255, 255);
      font-size: 16px;
      font-family: Nunito, sans-serif;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.44px;
      outline: none;
      padding: 15px 40px;
      &:hover {
        background-color: #454545;
        color: #fff;
      }
    }
  }
}
