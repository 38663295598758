$lightBackground: #fff;
$lightText: rgb(36, 36, 36);
$darkBackground: #242424;
$darkText: rgb(252, 250, 246);
$mainColor: rgb(247, 83, 105);

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  flex: 1;
  justify-content: center;
  min-height: 500px;
}
.contentContainer {
  background-color: $lightBackground;
  padding: 50px 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  a {
    color: $mainColor;
    &:hover {
      opacity: 0.6;
    }
  }
  .breadcrumbs {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 30px 0 30px 20px;
    font-family: OpenSans, sans-serif;
    font-size: 12px;
    font-style: italic;
    font-weight: normal;
    letter-spacing: 0.33px;
    .link {
      display: flex;
      color: $mainColor;
      text-decoration: underline;
    }
    .slash {
      display: flex;
      color: $mainColor;
      margin: 0 5px;
    }
    .active {
      display: flex;
      color: $lightText;
    }
  }
  .centered {
    text-align: center !important;
  }
  .header {
    margin-bottom: 40px;
    flex-direction: column;
    .faqTitle {
      color: $lightText;
      font-family: Nunito, sans-serif;
      font-style: normal;
      font-size: 32px;
      font-weight: 600;
      letter-spacing: 0.67px;
      margin: 0 auto;
      display: block;
      width: 100%;
      text-align: center;
    }
    .title {
      color: $lightText;
      font-family: Nunito, sans-serif;
      font-size: 60px;
      font-weight: normal;
      letter-spacing: 1.67px;
      line-height: 72px;
      font-style: normal;
      @media screen and (max-width: 760px) {
        font-size: 32px;
        line-height: 32px;
      }
    }
    .subtitle {
      color: $mainColor;
      font-family: Nunito, sans-serif;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0.67px;
    }
  }

  .description {
    margin-bottom: 20px;
    p {
      margin: 10px 0;
      color: $lightText;
      font-family: OpenSans, sans-serif;
      font-size: 24px;
      letter-spacing: 0.67px;
      line-height: 36px;
    }
  }

  .text {
    display: flex;
    flex: 1;
    margin-bottom: 30px;
    img {
      max-width: 100%;
    }
    p {
      color: $lightText;
      font-family: OpenSans, sans-serif;
      font-size: 18px;
      letter-spacing: 0.5px;
      line-height: 30px;
    }
    li {
      color: $lightText;
      font-family: OpenSans, sans-serif;
      letter-spacing: 0.5px;
      margin-bottom: 10px;
    }
  }

  .table {
    color: $lightText !important;
    .tableHeader {
      padding: 8px !important;
      font-size: 14px;
      font-weight: 700;
      background-color: $lightBackground !important;
    }

    .tableContent {
      padding: 8px !important;
      font-size: 14px;
    }

    .rowBackgroundColor {
      &:nth-child(odd) {
        background-color: $lightBackground;
      }
      &:nth-child(even) {
        background-color: #f9f9f9;
      }
    }
  }
  ul {
    padding-left: 0;
    li {
      margin-left: 20px;
      color: $lightText
    }
  }

  h1 {
    margin-bottom: 20px;
    color: $lightText;
    font-family: Nunito, sans-serif;
    font-size: 36px;
    font-weight: normal;
    letter-spacing: 1px;
  }

  h2 {
    margin-bottom: 10px;
    color: $lightText;
    font-family: Nunito, sans-serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.67px;
  }

  h3 {
    margin-bottom: 10px;
    color: $lightText;
    font-family: Nunito, sans-serif;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.67px;
  }

  h4 {
    margin-bottom: 10px;
    color: $lightText;
    font-family: Nunito, sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.67px;
    font-style: normal;
  }
}

.removeMargin {
  margin-top: -60px;
}

.removePadding {
  margin-top: -100px;
}

.darkContainer {
  background-color: $darkBackground;
  a {
    color: $mainColor;
  }
  .breadcrumbs {
    .link {
      color: $mainColor;
    }
    .slash {
      color: $mainColor;
    }
    .active {
      color: $darkText;
    }
  }
  .header {
    .faqTitle {
      color: $darkText;
    }
    .title {
      color: $darkText;
    }
    .subtitle {
      color: $mainColor;
    }
  }

  .description {
    p {
      color: $darkText;
    }
  }

  .text {
    p {
      color: $darkText !important;
    }
    li {
      color: $darkText;
    }
  }

  .table {
    color: $darkText !important;
    .tableHeader {
      background-color: rgb(253, 221, 225) !important;
    }

    .rowBackgroundColor {
      &:nth-child(odd) {
        background-color: #ffffff;
      }
      &:nth-child(even) {
        background-color: #f9f9f9;
      }
    }
  }

  ul{
    li {
      color: $darkText !important;
    }
  }

  h1, h2, h3, h4 {
    color: $darkText;
  }
}

